.splide__slide.is-active {
  transform: scale(1);
  z-index: 10;
  transition: 0.3s;
}

.splide__slide.is-prev,
.splide__slide.is-next {
  transform: scale(1);
  opacity: 0.7;
}

.splide__slide:not(.is-active):not(.is-prev):not(.is-next) {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.3s;
}
.image-an {
  transition: 0.3s;
}
.slide-image {
  width: 100%;
  height: 16rem;
  object-fit: cover;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: 0.3s;
}

.slide-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.splide__slide.is-active:hover .slide-overlay {
  opacity: 1;
  transition: 0.3s;
}

.slide-title {
  color: white;
  font-size: 1.125rem;
  font-weight: 600;
  transition: 0.3s;
}
