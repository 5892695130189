body {
  background-color: #0a192f !important;
  font-family: "Manrope", sans-serif;
  margin: 0;
}
.push {
  position: fixed;
}
.menu-button {
  margin-top: 0px !important;
}
.blur {
  pointer-events: none;
  filter: blur(4px);
  position: absolute;
}

/* In menu form */

.top_bar,
.bottom_bar {
  stroke-dasharray: 240px 910px;
}

.middle_bar {
  stroke-dasharray: 240px 240px;
}

/* In cross form */

.open .top_bar,
.open .bottom_bar {
  stroke-dashoffset: -650px;
}

.open .middle_bar {
  stroke-dasharray: 0px 220px;
  stroke-dashoffset: -120px;
}

/* Default styles for all devices */

.list-social-media {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.list-social-media::after {
  content: " ";
  display: block;
  width: 1px;
  height: 230%;
  margin-bottom: 20px;
  position: absolute;
  bottom: 100%;
  left: 10px;
  background: #ccd6f6;
}
.list-social-media::before {
  content: " ";
  display: block;
  width: 1px;
  height: 300%;
  position: absolute;
  top: 100%;
  left: 10px;
  background: #ccd6f6;
}
.list-social-media > li > a {
  color: #ccd6f6;
}
.list-social-media > li > a:hover {
  color: #62ecd0;
  transition: 0.3;
}
.list-social-media-item {
  margin: 0 0 20px 0;
}
.section-social-media {
  position: fixed;

  left: 10px;
  top: 35vh;
}
.hero {
  margin-top: 170px;
}
.big-heading {
  color: #ccd6f6;
  margin: 0px;
  font-size: clamp(40px, 7vw, 70px);
  font-weight: 600;
}
.big-heading-2 {
  color: #7283b4;
  margin: 0px;
  font-size: clamp(28px, 6vw, 50px);
  font-weight: 600;
}

.section-about {
  display: grid;
  grid-template-columns: 1fr 3fr;
  direction: ltr;
}

.section-name {
  color: #ccd6f6;
  font-size: clamp(15px, 6vw, 30px);
  font-weight: 600;
  margin-bottom: 30px;
}
.section-name::after {
  background-color: #8892b04a;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 20%;
  margin-left: 20px;
}

.project-content {
  position: relative;
  grid-area: 1 / 1 / -1 / 8;
  color: white;
  z-index: 2;
}
.project-image {
  box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  grid-area: 1 / 8 / -1 / -1;
  position: relative;
  z-index: 1;
}
.featured-project {
  position: relative;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(12, 2fr);
  -webkit-box-align: center;
  align-items: center;
  margin: 120px 0px;
  padding: 20px;
}

.project-details {
  border-radius: 6px;
  background-color: #112240;
  padding: 20px;
}
.featured-project-details {
  background-color: #112240;
  width: 60%;
  padding: 25px;
  border-radius: 10px;
  color: white;
  top: 10vh;
}

.project-tags {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}
.project-tags > li {
  display: inline-block;
  font-weight: bold;
  padding-right: 10px;
  color: rgb(184, 184, 184);
  font-size: 12px;
}

.project-img:hover {
  filter: grayscale(0%);
}
.project-img {
  filter: grayscale(60%);
}
.img-animation {
  --s: 15px;
  /* size of the frame */
  --b: 2px;
  /* border thickness */
  --w: 200px;
  /* width of the image */
  --c: #62ecd0;

  width: var(--w);
  aspect-ratio: 1;
  object-fit: cover;
  padding: calc(2 * var(--s));
  --_g: var(--c) var(--b), #0000 0 calc(100% - var(--b)), var(--c) 0;
  background: linear-gradient(var(--_g)) 50%/100% var(--_i, 100%) no-repeat,
    linear-gradient(90deg, var(--_g)) 50% / var(--_i, 100%) 100% no-repeat;
  outline: calc(var(--w) / 2) solid #62ecd045;
  outline-offset: calc(var(--w) / -2 - 2 * var(--s));
  transition: 0.4s;
}

.img-animation:hover {
  outline: var(--b) solid var(--c);
  outline-offset: calc(var(--s) / -2);
  --_i: calc(100% - 2 * var(--s));
}

.img-animation-v2 {
  --s: 15px;
  /* size of the frame */
  --b: 2px;
  /* border thickness */
  --w: 300px;
  /* width of the image */
  --c: #62ecd0;

  width: var(--w);
  aspect-ratio: 1;
  object-fit: cover;
  padding: calc(2 * var(--s));
  --_g: var(--c) var(--b), #ffffff00 0 calc(100% - var(--b)), var(--c) 0;
  background: linear-gradient(var(--_g)) 50%/100% var(--_i, 100%) no-repeat,
    linear-gradient(90deg, var(--_g)) 50% / var(--_i, 100%) 100% no-repeat;
  outline: calc(var(--w) / 2) solid #00000000;
  outline-offset: calc(var(--w) / -2 - 2 * var(--s));
  transition: 0.4s;
}

.img-animation-v2:hover {
  outline: var(--b) solid var(--c);
  outline-offset: calc(var(--s) / -2);
  --_i: calc(100% - 2 * var(--s));
}

.row-project {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
.row-featured-project {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
.col-project-details {
  border-radius: 6px;
  padding-top: 15px;
  background-color: #112240;
  color: white;
  border: 2px solid #62ecd000;
  transition: 0.3s !important;
}
.col-project-details:hover {
  border-radius: 6px;
  padding-top: 15px;
  background-color: #112240;
  color: white;
  border: 2px solid #62ecd045;
}
.desktop {
  display: block;
}
.mobile {
  display: none;
}
.mobile-img-only {
  display: none;
  width: 100%;
  border-radius: 10px;
  margin: auto;
  display: block;
}
.mobile-only {
  display: none;
}
.block-position {
  margin: auto;
  display: block;
}
.mob-img {
  width: 100%;
  border-radius: 14px;
}
/* Styles for devices with a screen width of 600px or less */
@media only screen and (max-width: 600px) {
  .container {
    font-family: "Manrope", sans-serif;
    width: 100%;
    padding: 10px 0px 10px;
  }
  .mobile-img-only {
    display: block;
  }
}

/* Styles for devices with a screen width of 768px or more */
@media only screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
  .row-featured-project {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .section-social-media {
    display: none;
  }
  .mobile {
    display: block;
  }
  .mobile-only {
    display: block;
  }
  .row {
    grid-template-columns: 1fr;
  }
}

/* Styles for devices with a screen width of 992px or more */
@media only screen and (max-width: 995px) {
  .row {
    grid-template-columns: 1fr 1fr;
  }
  .row-featured-project {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .section-about {
    display: grid;
    grid-template-columns: 1fr;
  }

  .mobile-only {
    display: block;
  }
  .img-animation {
    display: block;
    margin: auto;
  }
}
/* Styles for devices with a screen width of 1200px or more */
/* @media only screen and (max-width: 1200px) {
  
} */

/* Styles for devices with a screen width of 1200px or more */
@media only screen and (max-width: 6000px) {
  .project-img {
    display: none;
  }
  .mobile-only {
    display: block;
  }
  .row-featured-project {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}
